import React, { Component } from "react";
import "../Css/style.css";
import water from "../Images/Water.png";
import facebook from "../Images/facebook.png";
import instragram from "../Images/instagram.png";
import youtube from "../Images/youtube.png";
import twitter from "../Images/twitter.png";
import Productlandingpage from "./Productlandingpage";
import axios from "axios";
import Loader from "react-loader-spinner";

export default class Footerup extends Component {
  constructor() {
    super();
    this.state = {
      emailid: "",
      password: "",
      showpage: false,
      errormessage: "",
      otpReceived: true,
      otp: false,
      loader: false,
    };
  }

  generateOtp = () => {
    let _this = this;
    const { emailid } = this.state;
    _this.setState({ loader: true });
    axios
      .post(`https://sql-dev-india.thewitslab.com:5600/api/users/generateotp`, {
        emailid: emailid,
      })
      .then(function (response) {
        console.log("response", response);
        if (response && response.status === 200) {
          _this.setState({ otpReceived: false, loader: false });
        }
      })
      .catch(function (error) {
        console.log("error", error);
        _this.setState({
          errormessgae: "Invalid User",
          loader: false,
        });
      });
  };

  verifyOtp = () => {
    let _this = this;
    const { otp, emailid } = this.state;

    axios
      .post(`https://sql-dev-india.thewitslab.com:5600/api/users/verifyotp`, {
        otp: otp,
        emailid: emailid,
      })
      .then(function (response) {
        console.log("response", response);
        if (response && response.status === 200) {
          localStorage.setItem("user_token", response.data.message.token);
          window.open("/discover", "_blank");
          // _this.props.history.push('/discover');
          // _this.props.history.push('/discover');
          // window.location.reload();
        }
      })
      .catch(function (error) {
        console.log("error", error);
        _this.setState({
          errormessgae: "Invalid User",
        });
      });
  };

  render() {
    const { otpReceived, loader } = this.state;
    // if (loader) {
    //   return (
    //     <div className='loaderclass'>
    //       <Loader
    //         type='Puff'
    //         color='#00BFFF'
    //         height={100}
    //         width={100}
    //         // timeout={300000} //3 secs
    //       />
    //     </div>
    //   );
    // }
    return (
      <div>
        {/* {this.state.showpage && <Productlandingpage />} */}
        <div className="oi container_foo" id="Footerup">
          <img src={water} alt="Logo" class="img-fluid wateri" />
          <div className="spacingfromtop"></div>
          <div>
            {otpReceived && (
              <input
                type="email"
                className="ibox centered pl-4 mt-5"
                placeholder="Enter Your Email id "
                required
                onChange={(e) => {
                  if (
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                      e.target.value
                    )
                  ) {
                    this.setState({
                      errormessgae: " ",
                    });
                  } else {
                    this.setState({
                      errormessgae: "Enter Valid Emaild Id ",
                    });
                  }
                  this.setState({ emailid: e.target.value });
                }}
              />
            )}
            {!otpReceived && (
              <input
                type="Password"
                className="ibox centered pl-4 mt-5"
                placeholder="Enter OTP"
                onChange={(e) => {
                  this.setState({ otp: e.target.value });
                }}
                disabled={otpReceived}
              />
            )}

            {otpReceived && (
              <button
                className="ibtn centered"
                onClick={() => this.generateOtp()}
              >
                Generate OTP
              </button>
            )}

            {!otpReceived && (
              <>
                {" "}
                <button
                  className="ibtn centered "
                  onClick={() => this.verifyOtp()}
                  disabled={otpReceived}
                >
                  Discover
                </button>
                <p className="errorcolor">Please contact Peter for OTP</p>{" "}
              </>
            )}

            <label className="errorcolor">{this.state.errormessgae}</label>
          </div>
        </div>
        {/* <div className="rectangle_firstpage"></div> */}
        {/* <hr></hr>
        <div className='container'>
          <div>
            <p className='float-left pl-5 privacy-policy'> Legal Information</p>
            <p className='float-left pl-5 privacy-policy'>Terms of Service</p>
            <p className='float-left pl-5 privacy-policy'>Privacy Policy</p>
          </div>
          <div className='mel'>
            <img src={youtube} alt='Logo' class='img-fluid  mel' />
            <img src={instragram} alt='Logo' class='img-fluid pl-3' />
            <img src={twitter} alt='Logo' class='img-fluid pl-3' />
            <img src={facebook} alt='Logo' class='img-fluid pl-3' />
          </div>
        </div> */}
      </div>
    );
  }
}

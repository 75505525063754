import React, { Component } from 'react';
import '../Css/style.css';
import girl from '../Images/girl.png';
import girl1 from '../Images/image3.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-bootstrap/Carousel'


export default class Journey extends Component {
  render() {
    return (
      <div className="container-fluid">
      <div className='background_journey '>
        <div className='container mt-5' id='Journey'>
          <div className=' mask pt-5 pl-5 '>
            <div className='mt-5 jmar'>
              <p className='line-2 float-left mt-2'></p>
              <p className='our-cause pl-5  '>
                {' '}
                &nbsp; &nbsp;&nbsp;&nbsp;3 &nbsp;&nbsp;Water Journey
              </p>
            </div>
            <div className='row'>
              <div
                className='col-sm-4  testcol' >
                <p className='health-concern mt-4 jmar1'>Health Concern</p>
                <p className='ut-enim-ad-minim-ven12 jmar1'>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquipex ea commodo consequat. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquipex ea commodo consequat.{' '}
                 
                </p>
            
              </div>
              <div
                className='col-sm-8 '
          >
                {/* <img src={girl} alt='Logo' class='img-fluid g' /> */}
                {/* <Carousel renderThumbs={()=>null} >
                <div>
                <img src={girl} alt='Logo' class='img-fluid g' />
                 
                </div>
                <div>
                <img src={girl1} alt='Logo' class='img-fluid g' />
                   
                </div>
                <div>
                <img src={girl1} alt='Logo' class='img-fluid g' />
                
                   
                </div>
            </Carousel> */}

{/* 
            <Carousel autoPlay>
        <div>
            <img alt="" src={girl} />
            <p className="legend">Legend 1</p>
        </div>
        <div>
            <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-2.jpg" class='img-fluid g' />
            <p className="legend">Legend 2</p>
        </div>
        <div>
            <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-3.jpg" />
            <p className="legend">Legend 3</p>
        </div>
      
       
    </Carousel> */}

<Carousel>
  <Carousel.Item>
  <img src={girl} alt='Logo' class='img-fluid g' />
    
  </Carousel.Item>
  <Carousel.Item>
  <img src={girl1} alt='Logo' class='img-fluid g' />

   
  </Carousel.Item>
  <Carousel.Item>
  <img src={girl} alt='Logo' class='img-fluid g' />

  
  </Carousel.Item>
</Carousel>

            




            
            
              </div>
            </div>
            <div className='pt-5'></div>
            <div className='pt-5'></div>
            <div className='pt-5 mt-5'></div>
            <div className='pt-5'></div>
          </div>
        </div>
        <div className='pt-5 mt-5'></div>
      </div>
      </div>
    );
  }
}

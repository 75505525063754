import React, { Component } from "react";
import {
  Button,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
} from "react-bootstrap";
import { Link } from "react-scroll";
import "../Css/style.css";
import logo from "../Images/Logo.png";
import Kitchen from "../Images/Kitchennewrender.png";
import water from "../Images/Water.png";
import facebook from "../Images/facebook.png";
import instragram from "../Images/instagram.png";
import youtube from "../Images/youtube.png";
import twitter from "../Images/twitter.png";
import Bitmap from "../Images/Bitmap.png";
import Mobileapp from "../Components/Mobileapp";
import Features from "../Components/Features";
import Process from "../Components/Process";
import axios from "axios";
import Loader from "react-loader-spinner";

import arrow from "../Images/Arrow.png";
import $ from "jquery";
import WaterWave from "react-water-wave";
import BackgroundImage from "../Images/Background_homepage.png";

export default class Productlandingpage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isValidEmail: false,
      emailErrorMessage: "",
      isName: false,
      isMessage: false,
    };
  }
  componentDidMount() {
    this.setupBeforeUnloadListener();
  }
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.handleWindowClose();
    });
  };

  handleWindowClose() {
    localStorage.removeItem("user_token");
    return "";
  }

  handleContactSubmit = (event) => {
    event.preventDefault();

    const { isValidEmail, isName } = this.state;
    if (!event.target[0].value) {
      this.setState({ isName: true });
    }
    if (!event.target[1].value || isValidEmail) {
      this.setState({
        isValidEmail: true,
        emailErrorMessage: "Please enter email.",
      });
    }
    if (!event.target[2].value) {
      this.setState({ isMessage: true });
    }
    if (
      !event.target[0].value ||
      !event.target[1].value ||
      !event.target[2].value
    ) {
      return;
    }
    const payload = {
      name: event.target[0].value,
      emailid: event.target[1].value,
      message: event.target[2].value,
    };
    this.setState({ isLoading: true });
    let _this = this;
    axios
      .post(`http://api.elixr-products.com:9000/api/web/contactus`, payload)
      .then(function (response) {
        console.log("response", response);
        _this.setState({ isLoading: false });
        if (response && response.status === 200) {
          alert("Success");
        }
      })
      .catch(function (error) {
        _this.setState({ isLoading: false });
        console.log("error", error);
      });
  };

  render() {
    const {
      isLoading,
      isValidEmail,
      emailErrorMessage,
      isName,
      isMessage,
    } = this.state;
    // const getToken = localStorage.getItem('user_token');
    // if (!getToken) {
    //   this.props && this.props.history && this.props.history.push('/');
    //   return null;
    // }

    return (
      <div className="container-fluid">
        {isLoading && (
          <div className="loaderclass">
            <Loader
              type="Oval"
              color="#00BFFF"
              height={100}
              width={100}
              // timeout={300000} //3 secs
            />
          </div>
        )}
        <div className="" id="NavbarComP">
          <div className="container">
            <Navbar bg="light" expand="lg" fixed="top" className="">
              <Navbar.Brand href="#home">
                <img src={logo} alt="Logo" className="logoim" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto testnav ">
                  <Nav.Link href="#home" className="menu_text colortest1 pl-4">
                    <Link
                      activeClass="active"
                      to="NavbarComP"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                    >
                      What is Elixr?
                    </Link>
                  </Nav.Link>
                  <Nav.Link href="#link" className="menu_text pl-4">
                    <Link
                      activeClass="active"
                      to="Features"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                    >
                      Features
                    </Link>
                  </Nav.Link>
                  <Nav.Link href="#link" className="menu_text pl-4">
                    <Link
                      activeClass="active"
                      to="Process"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                    >
                      How it Works?
                    </Link>
                  </Nav.Link>
                  <Nav.Link href="#link" className="menu_text pl-4">
                    <Link
                      activeClass="active"
                      to="Mobileapp"
                      spy={true}
                      smooth={true}
                      offset={100}
                      duration={500}
                    >
                      Mobile App
                    </Link>
                  </Nav.Link>
                  <Nav.Link href="#link" className="menu_text pl-4">
                    <Link
                      activeClass="active"
                      to="getintouchdiv"
                      spy={true}
                      smooth={true}
                      offset={-30}
                      duration={500}
                    >
                      Get in Touch
                    </Link>
                  </Nav.Link>
                  <Nav.Link href="#link" className="menu_text pl-4 ">
                    <Link activeClass="" to="" spy={true} smooth={true}>
                      ENG
                    </Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <div>
              <div className="smart-hydration-for-Product_PP ">
                Making Clean <br />
                Structured Water
              </div>
              <div className="in-depth-innovative-Product mt-4">
                Helping people live a health conscious life
              </div>
            </div>
            <div className=" pt-5"></div>
          </div>
          <div>
            {" "}
            <img src={Kitchen} alt="Logo" class="img-fluid " />
          </div>
          <div className="mt-5 pt-5"></div>
        </div>
        {/* <Features />
        <Process />
        <Mobileapp /> */}

        <div>
          <div className="footer-Product" id="getintouchdiv">
            <div className="container getintouch ">
              <div className="row">
                <div className="col-sm-6 mobiledown">
                  <p className="line-2 float-left mt-2"></p>
                  <p className="our-cause pl-5 ml-4 ">
                    {" "}
                    &nbsp; &nbsp;&nbsp;4 &nbsp;&nbsp;Get in Touch
                  </p>
                  <p className="stay-connected">Stay Connected</p>
                  <p className="for-a-project-as-a">
                    For a project, as a partner or to stay close.
                  </p>
                  <form
                    onSubmit={(event) => this.handleContactSubmit(event)}
                    noValidate
                  >
                    <input
                      onChange={(event) => {
                        if (!event.currentTarget.value) {
                          this.setState({ isName: true });
                        } else {
                          this.setState({ isName: false });
                        }
                      }}
                      type="text"
                      className="rectangle-copy-9-Product pl-5 mt-5"
                      placeholder="Your
                Name"
                    />
                    <br />
                    {isName && (
                      <span className="errorClass">Please enter name.</span>
                    )}
                    <br />
                    <input
                      onChange={(event) => {
                        //console.log('event', event.currentTarget.value)
                        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        const result = re.test(
                          String(event.currentTarget.value).toLowerCase()
                        );
                        if (!result) {
                          this.setState({
                            isValidEmail: true,
                            emailErrorMessage: "Please enter valid email.",
                          });
                        } else {
                          this.setState({ isValidEmail: false });
                        }
                      }}
                      type="email"
                      className="rectangle-copy-9-Product pl-5 mt-4"
                      placeholder="Your
                Email"
                    />
                    <br />
                    {isValidEmail && (
                      <span className="errorClass">{emailErrorMessage}</span>
                    )}
                    <br />
                    <textarea
                      type="textarea"
                      className="rectangle-copy-9-Product pl-5 mt-4"
                      placeholder="Your
                Message"
                      rows="4"
                      cols="50"
                      onChange={(event) => {
                        if (!event.currentTarget.value) {
                          this.setState({ isMessage: true });
                        } else {
                          this.setState({ isMessage: false });
                        }
                      }}
                    />
                    <br />
                    {isMessage && (
                      <span className="errorClass">Please enter Message.</span>
                    )}
                    <br />
                    <button type="submit" className="ibtn-Product mt-5 ">
                      Send
                    </button>
                  </form>
                </div>
                <div className="col-sm-6">
                  <img src={Bitmap} alt="Logo" class="img-fluid simage" />
                </div>
              </div>
            </div>
            <div className="onlypad "></div>

            <hr className="hrcolor"></hr>
            <div className="container">
              <div>
                <p className="float-left pl-5 privacy-policy-product">
                  {" "}
                  Legal Information
                </p>
                <p className="float-left pl-5 privacy-policy-product">
                  Terms of Service
                </p>
                <p className="float-left pl-5 privacy-policy-product">
                  Privacy Policy
                </p>
              </div>
              <div className="mel">
                <img src={youtube} alt="Logo" class="img-fluid  mel" />
                <img src={instragram} alt="Logo" class="img-fluid pl-3" />
                <img src={twitter} alt="Logo" class="img-fluid pl-3" />
                <img src={facebook} alt="Logo" class="img-fluid pl-3" />
              </div>
            </div>
            <div className="pt-2"></div>
          </div>
        </div>
      </div>
    );
  }
}

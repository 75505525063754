import React, { Component } from "react";
import "../Css/style.css";
import quote from "../Images/quote.png";

export default class Approach extends Component {
  render() {
    return (
      <div>
        <div className="container" >
          <div className=" approacdiv  appv ">
            {/* <p className="line-2-copy-2 float-left mt-2"></p>
            <p className="our-approach  ">
              {" "}
              &nbsp; &nbsp;&nbsp;&nbsp;2 &nbsp;&nbsp;Our Approach
            </p> */}
            <div className="oapproach " >
              <div className="oval approachm " >
                <img id="Approach" src={quote} className="quotetest" />
                <div className="as-they-say ">
                  <p className="mb-0">As</p>
                 
                   <p className="mb-0">They</p>
                
                   <p className="mb-0">Say</p>
                </div>
                <div className="water-is-lifes-matt">
                  Water is life’s matter and matrix, mother and medium. There is
                  no life without water.
                </div>
                <div className="albert-szent-gyorg mt-3">
                  - Albert Szent-Gyorgyi <br />
                  &nbsp; M.D. Discoverer of Vitamin C
                </div>
              </div>
              <div className=""></div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

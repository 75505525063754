import React, { Component } from 'react';
import '../Css/style.css';
import childgirl from '../Images/childgirl.png';
import pro from '../Images/p12.png';
import Tabs from '../Components/Tabs';
import Panel from '../Components/Panel';

export default class Features extends Component {
  render() {
    return (
      <div className='container' id='Features'>
        <div className='fe'>
          <div className='line-2-copy-4-F float-left mt-2'></div>
          <p className='one-f float-left pl-3'></p>
          <p className='producty-features-f ml-5'>
            {' '}
            &nbsp;&nbsp;1 &nbsp;&nbsp;Producty & Features{' '}
          </p>
          <div className='just-because-youve-f mt-4'>
            Just because you've moved your meetings online, it doesn't
            mean you have to lose the human connection.We've designed
            Brightful Meeting Games.
          </div>
          <div className='ut-enim-ad-minim-ven-f mt-4'>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquipex ea commodo
           consequat. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi ut aliquipex ea  commodo consequat. Ut
            enim ad minim veniam,.
          </div>
          <div className='mt-5 pt-5'>
            <img src={pro} alt='Logo' class='img-fluid mx-auto d-block' />
          </div>

          <div className='mt-5 pt-5'></div>
          <div className='flex-container-feature'>
            <Tabs selected={0}>
              <Panel title='Filtered Water' className='filtered-water'>
                {/* <div className='mt-5 pt-5 imgtop'>
                  <img src={pro} alt='Logo' class='img-fluid mx-auto d-block' />
                </div> */}

                <div className='mt-5 '>
                  <p className='ut-enim-ad-minim-ven-df'>
                    1. Filtered Water content Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquipex ea
                    commodo consequat. Ut enim ad minim veniam, quis nostrud
                    exercitation ullamco laboris nisi ut aliquipex ea commodo
                    consequat. Ut enim ad minim veniam, quis nostrud
                    exercitation.
                  </p>
                </div>
              </Panel>
              <Panel title=' Just in Time  Subscription' className='ml-5 tes'>
                <div className='mt-5 '>
                  <p className='ut-enim-ad-minim-ven-df'>
                    2. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquipex ea commodo consequat. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquipex ea commodo consequat. Ut enim ad
                    minim veniam, quis nostrud exercitation.
                  </p>
                </div>
              </Panel>
              <Panel title=' Your own  Pharmacy'>
                {' '}
                <div className='mt-5 '>
                  <p className='ut-enim-ad-minim-ven-df'>
                    3. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquipex ea commodo consequat. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquipex ea commodo consequat. Ut enim ad
                    minim veniam, quis nostrud exercitation.
                  </p>
                </div>
              </Panel>
              <Panel title='  Your Drinking History'>
                {' '}
                <div className='mt-5 '>
                  <p className='ut-enim-ad-minim-ven-df'>
                    4. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquipex ea commodo consequat. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquipex ea commodo consequat. Ut enim ad
                    minim veniam, quis nostrud exercitation.
                  </p>
                </div>
              </Panel>
            </Tabs>
            {/* <div className='filtered-water'>Filtered Water</div>
            <div className='filtered-water'>
              Just in Time <br></br> Subscription
            </div>
            <div className='filtered-water'>
              Your own <br></br> Pharmacy
            </div>
            <div className='filtered-water'>
              Your Drinking<br></br> History
            </div> */}
          </div>
          <br></br>
          {/* <hr className='hrc'></hr> */}

          <div className='mt-5 pt-5'></div>
        
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import {
  Button,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
} from "react-bootstrap";
import { Link } from "react-scroll";
import "../Css/style.css";
import logo from "../Images/logo_new_v1.svg";
import arrow from "../Images/Arrow.png";
import $ from "jquery";
import WaterWave from "react-water-wave";
import BackgroundImage from "../Images/Background_homepage.png";
import facebook from "../Images/facebook.png";
import instragram from "../Images/instagram.png";
import youtube from "../Images/youtube.png";
import twitter from "../Images/twitter.png";
import Bitmap from "../Images/footerd.jpg";
import axios from "axios";
import Loader from "react-loader-spinner";

export default class NavbarCom extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isValidEmail: false,
      emailErrorMessage: "",
      isName: false,
      isMessage: false,
      formSubmitSuccess: false,
    };
  }
  componentDidMount() {
    this.setupBeforeUnloadListener();
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.handleWindowClose();
    });
  };

  handleWindowClose() {
    localStorage.removeItem("user_token");
    return "";
  }

  handleContactSubmit = (event) => {
    event.preventDefault();
    this.setState({ formSubmitSuccess: false });
    const { isValidEmail, isName } = this.state;
    if (!event.target[0].value) {
      this.setState({ isName: true });
    }
    if (!event.target[1].value || isValidEmail) {
      this.setState({
        isValidEmail: true,
        emailErrorMessage: "Please enter email.",
      });
    }
    if (!event.target[2].value) {
      this.setState({ isMessage: true });
    }
    if (
      !event.target[0].value ||
      !event.target[1].value ||
      !event.target[2].value ||
      isValidEmail
    ) {
      return;
    }
    const payload = {
      name: event.target[0].value,
      emailid: event.target[1].value,
      message: event.target[2].value,
    };
    this.setState({ isLoading: true });
    let _this = this;
    axios
      .post(`https://d166gry5f7eodc.cloudfront.net/api/web/contactus`, payload)
      .then(function (response) {
        console.log("response", response);
        _this.setState({ isLoading: false });
        if (response && response.status === 200) {
          document.getElementById("contact_us").reset();
          _this.setState({ formSubmitSuccess: true });
        }
      })
      .catch(function (error) {
        _this.setState({ isLoading: false });
        console.log("error", error);
      });
  };

  render() {
    const {
      isLoading,
      isValidEmail,
      emailErrorMessage,
      isName,
      isMessage,
      formSubmitSuccess,
    } = this.state;
    return (
      <div className="container-fluid">
        {isLoading && (
          <div className="loaderclass">
            <Loader
              type="Oval"
              color="#00BFFF"
              height={100}
              width={100}
              // timeout={300000} //3 secs
            />
          </div>
        )}

        <div className="" id="NavbarCom">
          <div className="background_homepage">
            {/* custom navbar */}

            <nav className="navbar">
              <div className="container-fluid">
                <a className="navbar-brand">
                  <img src={logo} alt="Logo" className="logoim" />
                </a>

                <Link
                  className="get_in_touch_btn"
                  activeClass="active"
                  to="getintouchdiv"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  Get in Touch
                </Link>
              </div>
            </nav>

            {/* navbar ends */}

            <div className="container ">
              <div className="section_1">
                <div className="smart-hydration-for ">
                  Helping people live a <br></br>health conscious life
                </div>
                <div className="in-depth-innovative">
                  Eco-Intelligent Positive Social Impact
                  <div className="arrowdiv">
                    <Link
                      activeClass="active"
                      to="Approach"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                    >
                      <img className="arrowdiv_img" src={arrow} alt="Logo" />
                    </Link>

                    <div className="explore ">
                      {" "}
                      <Link
                        activeClass="active"
                        to="Approach"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                      >
                        EXPLORE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" pt-5 "></div>
            </div>
            <div className="container-fluid">
              <div className=" rectangle_graident"></div>
            </div>
            <div className="mt-5"></div>
          </div>

          {/* {this.props.childComponent.causeComponent} */}
          {this.props.childComponent.approachComponent}
          {/* {this.props.childComponent.journeyComponent} */}
          {this.props.childComponent.discoverComponent}
          {/* {this.props.childComponent.footerupComponent} */}

          <div>
            <div className="footer-Product" id="getintouchdiv">
              <div className="container-fluid getintouch ">
                <div className="row stay_connected_main_section m-0">
                  <div className="get_in_touch_txt">
                    <p className="float-left mt-2"></p>
                    <p className="our-cause"> </p>
                  </div>
                  <div className="stay-connected-outer">
                    <p className="stay-connected">Stay Connected</p>
                  </div>
                  <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 col-12 mobiledown pl-0">
                    <p className="for-a-project-as-a">
                      For a project, as a partner or to stay close.
                    </p>
                    <form
                      onSubmit={(event) => this.handleContactSubmit(event)}
                      noValidate
                      id="contact_us"
                    >
                      <div>
                        <input
                          onChange={(event) => {
                            if (!event.currentTarget.value) {
                              this.setState({ isName: true });
                            } else {
                              this.setState({ isName: false });
                            }
                            this.setState({ formSubmitSuccess: false });
                          }}
                          type="text"
                          className="rectangle-copy-9-Product"
                          placeholder="Your Name"
                        />
                      </div>
                      {isName && (
                        <div className="pt-1">
                          <span className="errorClass">Please enter name.</span>
                        </div>
                      )}
                      <div>
                        <input
                          onChange={(event) => {
                            //console.log('event', event.currentTarget.value)
                            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            const result = re.test(
                              String(event.currentTarget.value).toLowerCase()
                            );
                            if (!result) {
                              this.setState({
                                isValidEmail: true,
                                emailErrorMessage: "Please enter valid email.",
                              });
                            } else {
                              this.setState({ isValidEmail: false });
                            }
                            this.setState({ formSubmitSuccess: false });
                          }}
                          type="email"
                          className="rectangle-copy-9-Product"
                          placeholder="Your Email"
                        />
                      </div>

                      {isValidEmail && (
                        <div className="pt-1">
                          <span className="errorClass">
                            {emailErrorMessage}
                          </span>
                        </div>
                      )}
                      <div>
                        <textarea
                          type="textarea"
                          className="rectangle-copy-9-Product-textarea "
                          placeholder="Your Message"
                          onChange={(event) => {
                            if (!event.currentTarget.value) {
                              this.setState({ isMessage: true });
                            } else {
                              this.setState({ isMessage: false });
                            }
                            this.setState({ formSubmitSuccess: false });
                          }}
                        />
                      </div>

                      {isMessage && (
                        <div className="">
                          <span className="errorClass">
                            Please enter message.
                          </span>
                        </div>
                      )}

                      {formSubmitSuccess && (
                        <div className="pt-1 success_text_">
                          <span class="successtext">
                            Thanks for contacting us. Our Support team executive
                            will get in touch with you.
                          </span>
                        </div>
                      )}
                      <div className="send_btn_">
                        <button type="submit" className="ibtn-Product  ">
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mobiledown_2">
                    <img src={Bitmap} alt="Logo" class="img-fluid simage" />
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                </div>
              </div>
              <div className="onlypad "></div>

              {/* <hr className="hrcolor"></hr> */}
              {/* <div className="container">
                    <div>
                      <p className="float-left pl-5 privacy-policy-product">
                        {" "}
                        Legal Information
                      </p>
                      <p className="float-left pl-5 privacy-policy-product">
                        Terms of Service
                      </p>
                      <p className="float-left pl-5 privacy-policy-product">
                        Privacy Policy
                      </p>
                    </div>
                    <div className="mel">
                      <img src={youtube} alt="Logo" class="img-fluid  mel" />
                      <img src={instragram} alt="Logo" class="img-fluid pl-3" />
                      <img src={twitter} alt="Logo" class="img-fluid pl-3" />
                      <img src={facebook} alt="Logo" class="img-fluid pl-3" />
                    </div>
                  </div> */}
              {/* <div className="pt-2"></div> */}
            </div>
          </div>
        </div>
        {/* )}
        </WaterWave> */}
      </div>
    );
  }
}

import React, { Component } from "react";
import "../Css/style.css";
import childgirl from "../Images/childd.png";
import play from "../Images/play.png";
export default class Cause extends Component {
  render() {
    return (
      <div>
        <div className="container" id="Cause">
          <div className="girldrinking">
            <img src={childgirl} alt="Logo" class="img-fluid gd" />
          </div>
        </div>
        <div className="conatiner-fluid rectangle_cause">
          <div className="Causecontent">
            {/* 
            <div className='one float-left'>
              <p className='line-2 float-left'></p>
              <p className='our-cause float-left pl-2 '></p>
              <p className='our-cause ml-5'>
                &nbsp;&nbsp;1 &nbsp;&nbsp;Our Cause
              </p>
              <div className='our-water-connects-u float-left '>
                Our Water<br></br> Connects Us
              </div>
              <div className='ut-enim-ad-minim-ven'>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco{' '}
                laboris nisi ut aliquipex ea commodo consequat. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquipex ea commodo consequat. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquipex ea
                commodo consequat. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquipex ea commodo
                consequat.{' '}
              </div>
            </div> */}
            {/* <div className='mt-5'></div>
            <div className='mt-5'></div> */}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-1"></div>

              <div className="col-sm-4 mt-4 spacingtest">
                <p className="line-2 mt-2 float-left"></p>
                <p className="our-cause">
                  &nbsp;&nbsp; &nbsp;&nbsp;1 &nbsp;&nbsp;Our Cause
                </p>
                <div className="our-water-connects-u ">
                  Our Water Connects Us
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ut-enim-ad-minim-ven mt-5 pt-4 downtest">
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco{" "}
                  laboris nisi ut aliquipex ea commodo consequat. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquipex ea commodo consequat. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquipex ea
                  commodo consequat. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquipex ea commodo
                  consequat.{" "}
                </div>
              </div>
            </div>
            <div className="pt-5"></div>
            <div className="pt-3 mt-5"></div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import '../Css/style.css';
import childgirl from '../Images/childgirl.png';
import Screen from '../Images/screen12.png';
export default class Mobileapp extends Component {
  render() {
    return (
      <div>
        {/* <div className='container'></div> */}
        <div className='conatiner-fluid ' id='Mobileapp'>
          <div className='Causecontent-p pt-5'>
            <div className='one-product'>
              <p className='line-2-product float-left mt-2'></p>
              <p className='our-cause-product '>
                {' '}
                &nbsp;&nbsp;3 &nbsp;&nbsp;Our Mobile App
              </p>
            </div>
            <div className='introducing-our-mobi'>
              Introducing our Mobile App
            </div>
            <div className='ut-enim-ad-minim-ven-p mt-3'>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquipex <br /> ea commodo consequat. Ut enim ad minim
              veniam, quis nostrud.
            </div>
          </div>
        </div>
        <div className='container-fluid pt-5'>

          
          <div className='mobile2'>
            <img
              src={Screen}
              alt='Logo'
              class='mx-auto d-block img-fluid '></img>
          </div>
          <div className='flex-container-mobile container textimage'>
            <div className='oleft'>
              <p className='convenient'>Life Paced</p>
              <div className='for-an-immersive-dri_ui'>
                for an immersive
                <br /> drinking experence.
              </div>
              <hr className='w-75 mt-5 pt-5' />
              <p className='convenient'>Eco-intelligent</p>
              <div className='for-an-immersive-dri_ui'>
                for an immersive
                <br /> drinking experence.
              </div>
              <hr className='w-75 mt-5 pt-5 ' />
              <p className='convenient'>Convenient</p>
              <div className='for-an-immersive-dri_ui'>
                for an immersive
                <br /> drinking experence.
              </div>
            </div>
            <div className='downa oright '>
              <p className='lifepaced'>Convenient</p>
              <div className='for-an-immersive-dri_ui'>
                for an immersive
                <br /> drinking experence.
              </div>
              <hr className='w-75 mt-5 pt-5' />
              <p className='lifepaced'>Economic</p>
              <div className='for-an-immersive-dri_ui'>
                for an immersive
                <br /> drinking experence.
              </div>
              <hr className='w-75 mt-5 pt-5 ' />
              <p className='lifepaced'>Eco-intelligent</p>
              <div className='for-an-immersive-dri_ui'>
                for an immersive
                <br /> drinking experence.
              </div>
            </div>
          </div>
      
        </div>

        <div className='mt-5 pt-5'></div>
{/* 
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4">
            <div className=' '>
              <p className='convenient'>Life Paced</p>
              <div className='for-an-immersive-dri_ui'>
                for an immersive
                <br /> drinking experence.
              </div>
              <hr className='w-75 mt-5 pt-5' />
              <p className='convenient'>Eco-intelligent</p>
              <div className='for-an-immersive-dri_ui'>
                for an immersive
                <br /> drinking experence.
              </div>
              <hr className='w-75 mt-5 pt-5 ' />
              <p className='convenient'>Convenient</p>
              <div className='for-an-immersive-dri_ui'>
                for an immersive
                <br /> drinking experence.
              </div>
            </div>
              
            </div>
            <div className="col-sm-4">
            <img
              src={Screen}
              alt='Logo'
              class=' img-fluid sizeimg '></img>
            </div>
            <div className="col-sm-4">
            <div className=' '>
              <p className='lifepaced'>Convenient</p>
              <div>
                for an immersive
                <br /> drinking experence.
              </div>
              <hr className='w-75 mt-5 pt-5' />
              <p className='lifepaced'>Economic</p>
              <div>
                for an immersive
                <br /> drinking experence.
              </div>
              <hr className='w-75 mt-5 pt-5 ' />
              <p className='lifepaced'>Eco-intelligent</p>
              <div>
                for an immersive
                <br /> drinking experence.
              </div>
            </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

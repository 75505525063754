import React, { Component } from "react";
import "../Css/style.css";
import wave from "../Images/ww.png";
import Wave from "react-wavify";

export default class Discover extends Component {
  render() {
    return (
      <div>
        {/* <div className="rectangle_discover">
        <div
            className='making-wave-making'
 >
            <p>
              Making Wave.
              <br />
              Making a New Direction.
            </p>
          </div>
          <div
            className='ut-enim-ad-minim-ven1011 mt-5 '
          >
            <p>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquipex ea commodo consequat. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquipex ea
              commodo consequat. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquipex ea commodo
              consequat. Ut enim ad minim veniam, quis nostrud exercitation
              ullamco laboris nisi ut aliquipex ea commodo consequat.{' '}
            </p>
          </div>
        </div> */}

        <div className="overimage  " id="Discover">
          <Wave
            fill="url(#gradient)"
            className=" testim"
            options={{
              height: 20,
              amplitude: 5,
              speed: 0.35,
              points: 3,
            }}
          >
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(90)">
                <stop offset="100%" stopColor="#2f2e2e" />
              </linearGradient>
            </defs>
          </Wave>

          <div className="making-wave-making1">
            <p className="mb-0">
              Near Term Action
             </p>
             <p>
              Long Term Impact
            </p>
          </div>

          <div className="ut-enim-ad-minim-ven1011_1 mt-2 ">
            <p>
              ELXR is actively partnering in all areas of production and sales
              to bring a uniquely designed and elegantly refined wellness
              offering to market.
            </p>
            <p className="mt-2">
              There is no greater form of wealth than good health and all that it
              affords. While operating in R&D and testing stealth mode, the ELXR
              team has accepted nothing less than amazing to ensure our
              customers don’t have to either.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

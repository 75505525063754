import React, { Component } from 'react';
import '../Css/style.css';
import childgirl from '../Images/childgirl.png';
import bottle from '../Images/bottlesetup.png';
export default class Process extends Component {
  render() {
    return (
      <div className='container-fluid' id='Process'>
        <div className='rectangle-process pt-5'>
          <div className='container mt-3 '>
            <div className='line-2-copy-2-process float-left mt-2'></div>
            <div className='process float-left pl-3'>
              {' '}
              &nbsp;&nbsp;2 &nbsp;&nbsp;Process
            </div>
            <br />
            <div className='experience-elixr mt-3'>
              Experience <br /> Elixr
            </div>

            <div className='row'>
              <div className='col-sm-6'>
                <div className='see-the-process-ho mt-5'>
                  See the process - How it works
                </div>
                <div className='mt-5'>
                  <div className='oval-pr  pt-2  float-left'>1</div>
                  <div className='step-1-will-go-here pt-1 pl-5'>
                  &nbsp;&nbsp; Step 1 will go here{' '}
                  </div>
                  {/* <p className='line-2-dot ml-3 mt-5 float-left  '></p> */}
                  <p className=' ut-enim-ad-minim-ven-ppp mt-4 ml-5 pl-3'>
                    Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquipex ea commodo
                    consequat. Ut enim ad. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut
                    aliquipex ea commodo consequat. quis nostrud
                    exercitation ullamco laboris nisi ut aliquipex ea commodo
                   
                    consequat.{' '}
                  </p>
                  <br/>
                  <div className='oval-pr  pt-2  float-left'>2</div>
                  <div className='step-1-will-go-here pt-1 pl-5'>
                  &nbsp;&nbsp; Step 2 will go here{' '}
                  </div>
                  {/* <p className='line-2-dot ml-3 mt-5 float-left  '></p> */}
                  <p className=' ut-enim-ad-minim-ven-ppp mt-4 ml-5 pl-3'>
                  Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquipex ea commodo
                    consequat. Ut enim ad. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut
                    aliquipex ea commodo consequat. quis nostrud
                    exercitation ullamco laboris nisi ut aliquipex ea commodo
                   
                    consequat.{' '}
                  </p>
                </div>
              </div>
              <div className='col-sm-6'>
                <img src={bottle} alt='Logo' class='img-fluid pclass' />
              </div>
            </div>
            <div className='mt-5 pt-5'></div>
          </div>
        </div>
      </div>
    );
  }
}

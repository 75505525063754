import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarCom from "./Components/NavbarCom";
import Cause from "./Components/Cause";
import Approach from "./Components/Approach";
import Journey from "./Components/Journey";
import Discover from "./Components/Discover";
import Footerup from "./Components/Footerup";
import Productlandingpage from "./Components/Productlandingpage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Mobileapp from "./Components/Mobileapp";
import Features from "./Components/Features";
import Process from "./Components/Process";
import { createBrowserHistory } from "history";

import AOS from "aos";

import "aos/dist/aos.css";
const history = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    AOS.init({
      duration: 2000,
    });
  }
  render() {
    let childComponent = {
      causeComponent: <Cause />,
      approachComponent: <Approach />,
      journeyComponent: <Journey />,
      discoverComponent: <Discover />,
      footerupComponent: <Footerup history={history} />,
    };
    return (
      <div className="">
        {/* <Productlandingpage /> */}
        <Router history={history}>
          <Switch>
            <Route exact path="/">
              <NavbarCom childComponent={childComponent} />
            </Route>
            <Route
              exact
              path="/discover"
              component={Productlandingpage}
            ></Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;

// function App() {
// let childComponent = {
//   causeComponent: <Cause />,
//   approachComponent: <Approach />,
//   journeyComponent: <Journey />,
//   discoverComponent: <Discover />,
//   footerupComponent: <Footerup history={history} />,
// };
//   return (
//     <div className=''>
//       <Router history={history}>
//         <Switch>
//           <Route exact path='/'>
//             <NavbarCom childComponent={childComponent} />
//           </Route>
//           <Route exact path='/discover' component={Productlandingpage}></Route>
//         </Switch>
//       </Router>
//     </div>
//   );

// export default App;
